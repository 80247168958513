<template>
  <table class="table table-striped table-hover table-fw-widget" id="table5">
    <thead>
    <tr>
      <th>Name</th>
      <!-- <th>Added</th> -->
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody v-if="plans">
    <tr v-for="plan in plans" :key="plan.id" class="odd gradeX">
      <td>{{ plan.name | planNameDisplay }}</td>
      <!-- <td>
        {{ new Date(plan.created).toLocaleString() }}
      </td> -->
      <td>
        <router-link :to="{ name: 'plan-details', params: { id: plan.id } }">
          <button class="btn btn-primary">View</button>
        </router-link>
      </td>
      <td class="center"></td>
      <td class="center"></td>
    </tr>
    </tbody>
    <tbody v-else>
    <tr>
      <td colspan="3">Loading...</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";

export default {
  name: "PlansTable",
  filters: {
    planNameDisplay: function (value) {
      if (value == "SME") {
        return "Router Management";
      } else if (value == "Kiosk") {
        return "Kiosk Management";
      } else if (value == "Premium") {
        return "Premium Plan";
      }
    },
  },
  data() {
    return {
      plans: null,
      error: "",
    };
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    getPlans() {
      axios
          .get('v1/plans')
          .then((response) => {
            if (response.status === 200) {
              this.plans = response.data;
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$swal("Error", `${error.response.data.detail}`, "error");
            }
          });
    },
  },
};
</script>
