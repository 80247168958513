<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'plans' }">Plans</router-link-active>
          </li>
        </ol>
      </nav>
    </div>

    <PlanComponent />
  </div>
</template>
<script>
import PlanComponent from "@/components/Plans/PlanComponent";

export default {
  components: { PlanComponent },
};
</script>
<style></style>
