<template>
  <div>
    <!-- <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-success">
          <div class="card-header card-header-divider">
            Add plan
            <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
            >
              Cancel
            </div>
          </div>
          <div class="card-body">
            <PlanCreationForm />
          </div>
        </div>
      </div>
    </div> -->
    <div v-if="!isHidden" class="row">
      <div class="col-sm-12">
        <div class="card card-table">
          <div class="card-header">
            All Plans
            <!-- <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
            >
              Add Plan
            </div> -->
          </div>
          <div class="card-body">
            <PlansTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import PlansTable from "@/components/Plans/Table";

export default {
  name: "PlanComponent",
  components: { PlansTable },
  data() {
    return {
      isHidden: false,
    };
  },
};
</script>
<style></style>
